@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');


$ch-family: Arial, Helvetica, "メイリオ", Meiryo, "HEITI TC", "黑體-繁", "微軟正黑體", "黑體", sans-serif;
$content-family: 'Noto Sans TC', sans-serif;
$en-family: Arial, Helvetica, sans-serif;


html,body{
	-webkit-text-size-adjust: 100%;   // for mobile fontsize
}

a{
	text-decoration: none;
	color: inherit;
	img{border: 0;}
}

:focus{
	outline: 0;
}

strong, b{
	font-weight: bold;
}

button{
	background-color: transparent;
	border: 0;
}

*{
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

img{
	vertical-align: middle; //remove bottom space
	max-width: 100%;
	// max-height: 100%;
}

/* override the default IOS styles */
input, textarea{
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}


/*

 \
 _\,,
"-=\~     _
   \\~___( ~
  _|/---\\_
  \        \	base.scss end

*/
