@import 'foundation';
@import "reset";
@import "base";
@import "public";

// --------------- custom faoundation settings here --------------------
$global-margin: 20px;
$global-padding: 20px;
$prototype-spacing-breakpoints: true;
$prototype-spacers-count: 8;
$breakpoint-classes: (small medium large xlarge xxlarge);
$breakpoints: (
	small: 0px,
	medium: 640px,
	large: 1025px,
	xlarge: 1200px,
	xxlarge: 1600px,
);

@include foundation-xy-grid-classes;
@include foundation-prototype-spacing;
@include foundation-flex-classes;
@include foundation-visibility-classes;
@include foundation-text-alignment;

.grid-y>.shrink{
	width: auto;
}


@mixin basic-hover() {
	cursor: pointer;
	transition: all .3s;
	&:hover{opacity: .6;}
}
@mixin basic-show-init($y: 50px) {
	transform: translateY($y);
	opacity: 0;
	transition: all 1s;
}
@mixin basic-show-finish($delay: 0s) {
	transform: translateY(0);
	opacity: 1;
	transition: all 1s $delay;
}
.ryder-show{
	@include basic-show-init;
	&.is-show{
		@include basic-show-finish;
	}
}


.m-logo{
	position: fixed;
	top: 40px;
	left: 60px;
	z-index: 69;
}
.m-menu{
	position: fixed;
	top: 50px;
	right: -6px;
	z-index: 69;
	width: 492px;
	height: 43px;
	background: url(../images/menu-bg.png) center center/cover no-repeat;
	padding: 3px 30px 0;
	li{
		@include mr(30px);
		&.current, &:hover{
			svg{
				.title{
					fill: #ff3222;
				}
				.deco{
					opacity: 1;
				}
			}
		}
	}
	svg{
		.title{
			fill: #000;
			transition: all .3s;
		}
		.num{}
		.deco{
			opacity: 0;
			transition: all .3s;
		}
	}
}
.indexWrap{
	position: relative;
	height: 100vh;
	.bgList{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		li{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
			transition: all .5s;
			&.is-show{
				opacity: 1;
			}
		}
	}
	.rinList{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		li{
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			bottom: 0;
			opacity: 0;
			transition: all .5s;
			&.is-show{
				opacity: 1;
			}
		}
	}
	.talk-container{
		position: absolute;
		width: 100%;
		left: 0;
		bottom: 0;
		padding: 20px 30px;
		.flag{
			z-index: 4;
			position: absolute;
			top: -26px;
			left: 100px;
		}
		.talk-area{
			height: 226px;
			background: url(../images/talk-bg.png) center center/cover no-repeat;
			border-radius: 24px;
			padding: 40px 70px 30px;
			overflow: hidden;
			position: relative;
			.chatbox{
				font-family: $content-family;
				font-size: 26px;
				letter-spacing: 1px;
				line-height: 1.5;
				color: #231815;
				overflow-y: auto;
				.question{}
				.answer-area{
					.answer{}
				}
				.loading-area{
					.name{
						margin-right: 5px;
					}
					.loading{
						position: relative;
						top: -9px;
						li{
							width: 4px;
							height: 4px;
							background-color: #231815;
							border-radius: 50%;
							@include mr(5px);
						}
					}
				}
				}
		}
	}
}
.ryderinput{
	position: relative;
	margin-top: 20px;
	input[type='text']{
		font-family: $content-family;
		font-size: 26px;
		letter-spacing: 1px;
		color: #231815;
		background-color: #fff;
		border: 2px solid #ff742d;
		border-radius: 18px;
		width: 100%;
		padding: 5px 10px;
	}
	.submit{
		position: absolute;
		top: 4px;
		right: 14px;
		@include basic-hover;
	}
}
#scene{
	position: relative;
	width: 100%;
	height: 100vh;
	overflow: auto;
    cursor: grab;
	#stage{
		width: 2860px;
		height: 2292px;
		position: relative;
		.area-1{
			@include m-map-area;
			position: absolute;
			top: 30.54%;
			left: 24.48%;
		}
		.area-2{
			@include m-map-area;
			position: absolute;
			top: 7.5%;
			left: 40.38%;
		}
		.area-3{
			@include m-map-area;
			position: absolute;
			top: 13.87%;
			left: 62.8%;
		}
		.area-4{
			@include m-map-area;
			position: absolute;
			top: 47.21%;
			left: 10.31%;
		}
		.area-5{
			@include m-map-area;
			position: absolute;
			top: 40.84%;
			left: 44.2%;
		}
		.area-6{
			@include m-map-area;
			position: absolute;
			top: 74.39%;
			left: 75.35%;
		}
		.area-7{
			@include m-map-area;
			position: absolute;
			top: 70.03%;
			left: 27.66%;
		}
		.animal{
			position: absolute;
			right: 50px;
			bottom: -40px;
		}
		.info{
			opacity: 0;
			transition: all .5s;
		}
	}
}